export const environment = {
  production: true,
  apiUrl: 'https://api.stage.edg-pro.com',
  frontUrl: 'https://stage.edg-pro.com',
  portalUrl: 'https://stage.edg-pro.com',
  domain: 'stage.edg-pro.com',
  tokenName: 'token.stage.edg-pro.com',
  sentry: {
    dsn: 'https://20a531cdd5b543a6ae19bd66d43fa71f@o4504261033000960.ingest.sentry.io/4504261120163840',
    env: 'stage',
  },
  binaries: {
    installer: 'https://cdn.stage.edg-pro.com/binaries/Installer.zip',
    moduleBuilder: 'https://cdn.stage.edg-pro.com/binaries/ModuleBuilder.zip',
  },
  contact: 'eduardbcomabsoft@gmail.com',
};
